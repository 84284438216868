







































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'

@Component({
  components: {
    CardTitleWithIcon: () => import('@/components/Misc/CardTitleWithIcon.vue')
  }
})
export default class Help2FA extends Vue {
  @UserModule.State currentUser

  authyDownloadLink = 'https://authy.com/download/'
  googleAuthDownloadLink = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
}
